import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./themes/styles.scss";
import "bootstrap";
import Axios from "./helpers/axios";
import Socket from "./helpers/socket";

const app = createApp(App).use(store).use(router);

app.config.globalProperties.$axios = Axios;
app.config.globalProperties.$socket = Socket;

router.isReady().then(() => {
  app.mount("#app");
});
