<template>
  <div class="mainContainer">
    <div
      class="btnContainer"
      @click="status = !status"
      :class="{ change: status }"
    >
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <Transition>
      <div v-show="status" class="menu" @click="status = false">
        <router-link to="/Home">Home</router-link>
        <router-link to="/AddNewAdmin">Add New Admin</router-link>
        <router-link to="/add-app">Add App</router-link>
        <router-link to="/remove-app">Remove App</router-link>
        <a @click.prevent="logout">Log Out</a>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  name: "Navigation",
  props: ["menu"],
  data: () => ({
    status: false,
  }),
  watch: {
    status(status) {
      this.$emit("changeMenu", status);
    },
    menu(status) {
      this.status = status;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logOut");
      this.$router.push("/sign-in");
    },
  },
};
</script>
<style lang="scss" scoped>
.btnContainer {
  display: inline-block;
  cursor: pointer;
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #7a00cc;
    margin: 6px 0;
    transition: 0.4s;
  }
  &.change {
    .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }
}
.mainContainer {
  position: relative;
  .menu {
    font-weight: 700;
    border: 1px solid gray;
    border-radius: 5px;
    position: absolute;
    top: 50px;
    left: 50px;
    width: 180px;
    background-color: cornsilk;
    padding: 1px;
    display: flex;
    flex-direction: column;
    text-align: left;
    a {
      padding: 14px 16px;
      margin: 2px;
      background-color: whitesmoke;
      text-decoration: none;
      color: #7a00cc;
      cursor: pointer;
      &:hover {
        background-color: #7a00cc;
        color: white;
      }
    }
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
