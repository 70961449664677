import axios from "@/helpers/axios";

export const state = {
  first_name: null,
  last_name: null,
  phone: null,
  email: null,
  token: localStorage.getItem("token"),
  valid: false,
};

export const mutations = {
  ["SET_USER"](state, user) {
    state.first_name = user.first_name;
    state.last_name = user.last_name;
    state.phone = user.phone;
    state.email = user.email;
    state.token = user.token;
    state.valid = true;
  },
  ["LOGOUT_USER"](state) {
    state.first_name = null;
    state.last_name = null;
    state.phone = null;
    state.email = null;
    state.token = null;
    state.valid = false;
  },
};

export const actions = {
  getUser({ commit }) {
    axios
      .get("check-auth")
      .then((res) => {
        commit("SET_USER", res.data);
      })
      .catch(() => {
        console.log("no user");
      });
  },
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  async logOut({ commit }) {
    await axios
      .get("/sign-out")
      .then(() => {
        this.dispatch("general/setAlert", {
          type: "success",
          msg: "Logged-Out!",
        });
        commit("LOGOUT_USER");
        localStorage.clear();
      })
      .catch(() => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "Oops! Something went WRONG!!",
        });
      });
  },
};

export const getters = {
  user: (state) => state,
  token: (state) => state.token,
  valid: (state) => state.valid,
};
