<template>
  <div @click="closeMenu">
    <div id="navbar">
      <div>
        <Navigation
          v-if="authenticated"
          @changeMenu="changeMenu"
          :menu="menu"
        />
      </div>
      <div id="logo">
        <img
          src="https://switchq.co.il/assets/img/logo.png"
          alt="SwitchQ Logo"
          style="cursor: pointer"
          @click="$router.push(`/home`)"
        />
        Websockets Admin
      </div>
      <div></div>
    </div>
    <router-view />
  </div>
  <Toaster />
</template>

<script>
import Toaster from "./components/Toaster.vue";
import Navigation from "./components/Navigation.vue";
export default {
  name: "App",
  components: {
    Toaster,
    Navigation,
  },
  data: () => ({
    menu: false,
    closeableMenu: false,
  }),
  created() {
    this.$store.dispatch("general/startTimeInterval");
  },
  mounted() {
    this.$store.dispatch("user/getUser");
    // Here we will call the function
    // We are doing it here beacuse this is the top-level component that will alwats load!
    if (this.authenticated) {
      this.getAdmins();
      this.getApps();
      this.socket();
    }
  },
  computed: {
    authenticated() {
      return this.$store.getters["user/valid"];
    },
    userName() {
      const user = this.$store.getters["user/user"];
      return user.first_name;
    },
    token() {
      return this.$store.getters["user/token"];
    },
  },
  watch: {
    authenticated(value, old) {
      if (!old && value) {
        this.getAdmins();
        this.getApps();
        this.socket();
      } else {
        this.$socket.disconnect();
      }
    },
  },
  methods: {
    closeMenu() {
      if (this.menu && this.closeableMenu) {
        this.menu = false;
      }
    },
    changeMenu(status) {
      this.menu = status;
      this.closeableMenu = false;
      setTimeout(() => {
        this.closeableMenu = true;
      }, 100);
    },
    async socket() {
      this.$socket.auth.token = this.token;
      this.$socket.auth.name = this.userName;
      this.$socket.connect();
      this.$socket.on("allApps", (apps) => {
        this.$store.dispatch("apps/setApps", apps);
      });
      this.$socket.on("all-data", (apps) => {
        // console.log("apps", apps["/localble"].logs);
        // apps["/localble"].logs.forEach((con) => {
        //   console.log("connection", con.id, con.upload);
        // });
        this.$store.dispatch("appDetails/setAppDetails", apps);
      });
      this.$socket.on("connected-admins", (admins) => {
        this.$store.dispatch("admins/setConnectedAdmins", admins);
      });
    },
    getApps() {
      // here we will dipatch a function to get all the apps form the backend...
      // this.$store.dispatch("apps/getApps");
    },
    getAdmins() {
      // here we will dipatch a function to get all the apps form the backend...
      this.$store.dispatch("admins/getAdmins");
    },
  },
};
</script>
<style scoped lang="scss">
#navbar {
  display: flex;
  justify-content: space-between;
  div {
    min-width: 80px;
    &#logo {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      color: #7a00cc;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-size: 20pt;
      img {
        text-align: center;
        width: 100%;
        max-width: 100px;
        height: auto;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
