import { createRouter, createWebHistory } from "vue-router";
import axios from "../helpers/axios";

const routes = [
  {
    path: "/sign-in",
    name: "OnStart",
    component: () => import("../views/OnStart.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/addNewAdmin",
    name: "AddNewAdmin",
    component: () => import("../views/AddNewAdmin.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/add-app",
    name: "AddApp",
    component: () => import("../views/AddApp.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/remove-app",
    name: "RemoveApp",
    component: () => import("../views/RemoveApp.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/app/:name",
    name: "AppPage",
    component: () => import("../views/AppPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/app/:name/delete",
    name: "RemoveSpecificApp",
    component: () => import("../views/RemoveApp.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/app/:name/edit",
    name: "EditComp",
    component: () => import("../components/EditComp.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const validAuth = await checkAuth();
  if (to.meta.requiresAuth && !validAuth) {
    return {
      path: "/sign-in",
      query: { redirect: to.fullPath },
    };
  } else if (!to.meta.requiresAuth && validAuth) {
    return {
      path: "/",
    };
  }
});

export default router;

let checkedAuth = false;
let authValid = false;
function checkAuth() {
  return new Promise((resolve) => {
    if (!checkedAuth || !authValid) {
      axios
        .get("check-auth")
        .then(() => {
          authValid = true;
        })
        .catch(() => {
          authValid = false;
        })
        .finally(() => {
          checkedAuth = true;
          resolve(authValid);
        });
    } else {
      resolve(authValid && localStorage.getItem("token"));
    }
  });
}
