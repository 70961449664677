import Axios from "@/helpers/axios";

export const state = {
  admins: [],
  connections: {},
};

export const mutations = {
  ["SET_ADMINS"](state, admins) {
    state.admins = Object.values(admins);
  },
  ["SET_CONNECTIONS"](state, connections) {
    state.connections = Object.values(connections);
  },
  ["SET_ADMIN"](state, admin) {
    state.admins.push(admin);
  },
};

export const actions = {
  setAdmins({ commit }, admins) {
    commit("SET_ADMINS", admins);
  },
  setConnectedAdmins({ commit }, connections) {
    commit("SET_CONNECTIONS", connections);
  },
  getAdmins({ commit }) {
    Axios.get("admins")
      .then((res) => {
        commit("SET_ADMINS", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  async setUser({ commit }, { ...admin }) {
    await Axios.post("register", admin)
      .then((res) => {
        this.dispatch("general/setAlert", {
          type: "success",
          msg: "User Created Successfully!",
        });
        console.log("res", res);
        commit("SET_ADMIN", admin);
      })
      .catch((err) => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "Oops! Something went Wrong!",
        });
        console.log("err", err);
      });
  },
};

export const getters = {
  admins: (state) => state.admins,
  connections: (state) => state.connections,
  adminsSearch: (state) => (search) => {
    return state.admins.filter((admin) => {
      return admin.firsName.toLowerCase().includes(search.toLowerCase());
    });
  },
  admin: (state) => (search) => {
    return state.admins.filter((admin) => {
      return admin.firstName.toLowerCase().includes(search.toLowerCase());
    })[0];
  },
};
