import io from "socket.io-client";

const env = process.env.NODE_ENV;
const url =
  env == "development"
    ? "http://localhost:3001/admin"
    : "https://server.websockets.switchq.com/admin";

export default io.connect(url, {
  path: "/sqws",
  auth: { token: localStorage.getItem("token") },
  autoConnect: false,
});
