import axios from "axios";

const headers = {};
const token = localStorage.getItem("token");
if (token) {
  headers.Authorization = "Bearer " + token;
}

const env = process.env.NODE_ENV;
const url =
  env == "development"
    ? "http://localhost:3001/api/admin/"
    : "https://server.websockets.switchq.com/api/admin";

// console.log("env", env);

export default axios.create({
  baseURL: url,
  timeout: 10000,
  headers: headers,
});
