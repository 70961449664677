import createUUID from "../../helpers/uuid";
import dayjs from "dayjs";

function sleep(seconds = 5) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), seconds * 1000);
  });
}

export const state = {
  locale: "en",
  alerts: {},
  time: dayjs(),
  timeInterval: false,
};

export const mutations = {
  ["START_TIME_INTERVAL"](state) {
    if (!state.timeInterval) {
      setInterval(() => {
        this.time = dayjs();
      }, 1000);
    }
  },
  ["SET_LOCALE"](state, locale) {
    state.locale = locale;
  },
  ["UNSET_ALERT"](state, uuid) {
    let alerts = { ...state.alerts };
    delete alerts[uuid];
    state.alerts = alerts;
  },
  ["SET_ALERT"](state, alert) {
    let alerts = { ...state.alerts };
    const uuid = createUUID();
    alerts[uuid] = alert;
    console.log("SET_ALERT", alerts);
    state.alerts = alerts;
    sleep(alerts.ttl).then(() => {
      this.commit("general/UNSET_ALERT", uuid);
    });
  },
};

export const actions = {
  startTimeInterval({ commit }) {
    commit("START_TIME_INTERVAL");
  },
  setLocale({ commit }, locale) {
    commit("SET_LOCALE", locale);
  },
  setAlert({ commit }, alert) {
    commit("SET_ALERT", alert);
  },
  unsetAlert({ commit }, uuid) {
    commit("UNSET_ALERT", uuid);
  },
};

export const getters = {
  locale: (state) => state.locale,
  alerts: (state) => Object.values(state.alerts),
};
