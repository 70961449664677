export const state = {
  apps: [],
};

export const mutations = {
  ["APP_DETAILS"](state, apps) {
    state.apps = { ...apps };
  },
};

export const actions = {
  setApps({ commit }, app) {
    commit("SET_APP", app);
  },
  async setAppDetails({ commit }, appsDetails) {
    commit("APP_DETAILS", appsDetails);
  },
};

export const getters = {
  apps: (state) => state.apps,
  app: (state) => (namespace) => state.apps[namespace.toLowerCase()],
  appConnections: (state) => (namespace) => {
    const app = state.apps[namespace.toLowerCase().split(" ").join("-")];
    if (app) {
      return app.connectionData.connectionCount;
    } else {
      return null;
    }
  },
};
