<template>
  <div class="alerts">
    <div v-for="(alert, i) in alerts" :class="'toaster ' + alert.type" :key="i">
      {{ alert.msg }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Toaster",
  computed: {
    alerts() {
      return this.$store.getters["general/alerts"];
    },
    data() {
      return console.log(this.props);
    },
  },
};
</script>
<style scoped lang="scss">
.alerts {
  position: fixed;
  top: 100px;
  left: 160px;
  .toaster {
    width: 300px;
    font-weight: 800;
    margin-left: -125px;
    color: rgb(3, 0, 0);
    text-align: center;
    border-radius: 8px;
    padding: 16px;
    z-index: 1;
    font-size: 17px;
  }
}

.success {
  -webkit-animation: fadein 0.5s, fadeout 1s 4s;
  animation: fadein 0.5s, fadeout 1s 4s;
  background-color: rgb(64, 224, 64);
}
.failed {
  -webkit-animation: fadein 0.5s, fadeout 1s 4s;
  animation: fadein 0.5s, fadeout 1s 4s;
  background-color: rgb(143, 63, 63);
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
