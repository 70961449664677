import Axios from "@/helpers/axios";

export const state = {
  apps: [],
};

export const mutations = {
  ["SET_APPS"](state, apps) {
    state.apps = Object.values(apps).map((app) => {
      return app;
    });
  },
  ["SET_APP"](state, app) {
    console.log("SET_APP", app);
    state.apps.push(app);
  },
  ["EDIT_APP"](state, { app, name }) {
    console.log("EDIT_APP", app);
    state.apps = state.apps.map((a) => {
      if (a.name === name) {
        a = { ...app };
      }
      return a;
    });
    console.log("state: ", state.apps);
  },
  ["UNSET_APP"](state, app) {
    state.apps = state.apps.filter((a) => a.name != app);
  },
};

export const actions = {
  setApps({ commit }, apps) {
    commit("SET_APPS", apps);
  },
  getApps({ commit }) {
    Axios.get("apps")
      .then((res) => {
        console.log("apps ", res.data);
        commit("SET_APPS", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  async setApp({ commit }, { ...app }) {
    await Axios.post("apps/new", app)
      .then(() => {
        this.dispatch("general/setAlert", {
          type: "success",
          msg: "Created Successfully!",
        });
        commit("SET_APP", app);
      })
      .catch(() => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "Oops! Something went Wrong!",
        });
      });
  },
  async updateApp({ commit }, req) {
    await Axios.post("apps/edit", req)
      .then(() => {
        this.dispatch("general/setAlert", {
          type: "success",
          msg: "Updated Successfully!",
        });
        commit("EDIT_APP", req);
      })
      .catch(() => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "Update FAILED!",
        });
      });
  },
  async delApp({ commit }, name) {
    await Axios.post("apps/del", { name: name })
      .then(() => {
        this.dispatch("general/setAlert", {
          type: "success",
          msg: "Removed Successfully!",
        });
        commit("UNSET_APP", name);
      })
      .catch(() => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "Remove FAILED!",
        });
      });
  },
};

export const getters = {
  apps: (state) => state.apps,
  appsSearch: (state) => (search) => {
    return state.apps.filter((app) => {
      return app.name.toLowerCase().includes(search.toLowerCase());
    });
  },
  app: (state) => (search) => {
    return state.apps.filter((app) => {
      return app.name.toLowerCase().includes(search.toLowerCase());
    })[0];
  },
};
